import Swiper from "swiper/bundle";
import "swiper/css/bundle";

const mainSymptoms = new Swiper(".main-symptoms-swiper", {
  slidesPerView: 1,
  spaceBetween: 20,
  speed: 700,
  breakpoints: {
    768: {
      spaceBetween: 15,
      slidesPerView: 2,
    },
    1200: {
      spaceBetween: 20,
      slidesPerView: 3,
    },
    1400: {
      spaceBetween: 20,
      slidesPerView: 4.1,
    },
  },

  navigation: {
    prevEl: ".main-symptoms-swiper .prev",
    nextEl: ".main-symptoms-swiper .next",
  },

  pagination: {
    el: ".swiper-pagination",
    dynamicBullets: true,
    clickable: true,
  },
  grabCursor: true,
  autoHeight: true,
});
const mainDoctor = new Swiper(".main-doctors-swiper", {
  slidesPerView: 1,
  spaceBetween: 20,
  speed: 700,
  breakpoints: {
    768: {
      spaceBetween: 15,
      slidesPerView: 2,
    },
    1200: {
      spaceBetween: 20,
      slidesPerView: 3,
    },
    1400: {
      spaceBetween: 20,
      slidesPerView: 4.1,
    },
  },

  navigation: {
    prevEl: ".main-doctors-swiper .prev",
    nextEl: ".main-doctors-swiper .next",
  },

  pagination: {
    el: ".swiper-pagination",
    dynamicBullets: true,
    clickable: true,
  },
  grabCursor: true,
  autoHeight: true,
});

const mainTreatment = new Swiper(".main-treatment-swiper", {
  slidesPerView: 1,
  spaceBetween: 20,
  speed: 700,
  breakpoints: {
    768: {
      spaceBetween: 15,
      slidesPerView: 2,
    },
  },

  navigation: {
    prevEl: ".main-treatment-swiper .prev",
    nextEl: ".main-treatment-swiper .next",
  },

  pagination: {
    el: ".swiper-pagination",
    dynamicBullets: true,
    clickable: true,
  },
  autoHeight: true,
});

const mainResults = new Swiper(".main-results-swiper", {
  slidesPerView: 1,
  spaceBetween: 20,
  speed: 700,

  navigation: {
    prevEl: ".main-results-swiper .prev",
    nextEl: ".main-results-swiper .next",
  },
  breakpoints: {
    1200: {
      slidesPerView: "auto",
    },
  },

  pagination: {
    el: ".swiper-pagination",
    dynamicBullets: true,
    clickable: true,
  },
  // autoHeight: true,
});

const mainVideoSwiper = new Swiper(".main-video-swiper", {
  slidesPerView: 1,
  spaceBetween: 40,
	simulateTouch: false,
	
  pagination: {
    el: ".swiper-pagination",
    dynamicBullets: true, 
    clickable: true,
  },
  navigation: {
    prevEl: ".main-video-swiper .prev",
    nextEl: ".main-video-swiper .next",
  },
  breakpoints: {
    992: {
      spaceBetween: 20,
    },
  },

  autoHeight: true,
});
const mainVideoTitle = new Swiper('.main-video-title-swiper', {
	slidesPerView: 1,
	simulateTouch: false,
	spaceBetween: 40,
	breakpoints: {
    992: {
      spaceBetween: 20,
    },
  },
})

mainVideoSwiper.on('slideNextTransitionStart', function () {
	mainVideoTitle.slideNext();
});
mainVideoSwiper.on('slidePrevTransitionStart', function () {
	mainVideoTitle.slidePrev();
});



const faqVideo = new Swiper(".faq-video-swiper", {
  slidesPerView: 1,
	initialSlide: 1,
  spaceBetween: 20,
	simulateTouch: false,
  speed: 700,

  navigation: {
    prevEl: "#video-answers .prev",
    nextEl: "#video-answers .next",
  },
	pagination: {
		el: "#video-answers .swiper-pagination",
		dynamicBullets: true,
		clickable: true,
	},

  autoHeight: true
});
const faqVideoTitle = new Swiper('.faq-video-title-swiper', {
	slidesPerView: 1,
	autoHeight: true,
	initialSlide: 1,
	simulateTouch: false,
	spaceBetween: 20,
	speed: 700,
})
// faqVideo.on('slideChangeTransitionEnd', function () {
//   faqVideoTitle.slideTo(faqVideo.activeIndex);
// });
faqVideo.on('slideNextTransitionStart', function () {
	faqVideoTitle.slideNext();
});
faqVideo.on('slidePrevTransitionStart', function () {
	faqVideoTitle.slidePrev();
});

let blogSwiper;

function initBlogSwiper() {
  if (window.innerWidth < 991) {
    if (!blogSwiper) {
      // Initialize Swiper only if it hasn't been initialized yet
      blogSwiper = new Swiper(".blog-list-swiper", {
        slidesPerView: 1,
        spaceBetween: 20,
        speed: 700,

        navigation: {
          prevEl: ".blog-list-swiper .prev",
          nextEl: ".blog-list-swiper .next",
        },

        pagination: {
          el: ".swiper-pagination",
          dynamicBullets: true,
          clickable: true,
        },
        autoHeight: true,
      });
    }
  } else {
    if (blogSwiper) {
      // Destroy Swiper if it has been initialized
      blogSwiper.destroy(true, true);
      blogSwiper = null;
    }
  }
}

// Initialize on load
initBlogSwiper();

// Reinitialize on resize
window.addEventListener("resize", function () {
  initBlogSwiper();
});

const singleDoctor = new Swiper(".single-doctors-swiper", {
  slidesPerView: 1,
  spaceBetween: 20,
  speed: 700,
  breakpoints: {
    768: {
      spaceBetween: 15,
      slidesPerView: 2,
    },
    992: {
      spaceBetween: 20,
      slidesPerView: 3,
    },
  },

  navigation: {
    prevEl: ".single-doctors-swiper .prev",
    nextEl: ".single-doctors-swiper .next",
  },

  pagination: {
    el: ".swiper-pagination",
    dynamicBullets: true,
    clickable: true,
  },
  grabCursor: true,
  autoHeight: true,
});

const tourismSwiper = new Swiper(".tourism-swiper", {
  slidesPerView: 1,
  spaceBetween: 20,
  speed: 700,
  breakpoints: {
    768: {
      spaceBetween: 15,
      slidesPerView: 2,
    },
  },

  navigation: {
    prevEl: ".tourism-swiper .prev",
    nextEl: ".tourism-swiper .next",
  },

  pagination: {
    el: ".swiper-pagination",
    dynamicBullets: true,
    clickable: true,
  },
  grabCursor: true,
  autoHeight: true,
});

const swiperTitles = new Swiper(".titles-swiper", {
  slidesPerView: "auto",
  spaceBetween: 24,
});

const swiperMainBlog = new Swiper(".main-blog-swiper", {
  slidesPerView: 1,
  // autoHeight: true,
  speed: 1500,
  // loop: true,
  spaceBetween: 40,
  breakpoints: {
    992: {
      slidesPerView: 3,
      grabCursor: true,
    },
  },
  pagination: {
    el: ".swiper-pagination",
    dynamicBullets: true,
    clickable: true,
  },
  navigation: {
    prevEl: ".main-blog-swiper .prev",
    nextEl: ".main-blog-swiper .next",
  },
});


// Отримуємо всі елементи, на які можна клікнути в першому свайпері
// Отримуємо всі елементи заголовків, на які можна клікнути
const headingItems = document.querySelectorAll(".citys-heading-list__item");

// Додаємо обробник подій для кожного елементу заголовка
headingItems.forEach((item, index) => {
  item.addEventListener("click", () => {
    // Додаємо клас active до клікнутого елементу
    headingItems.forEach((item) => {
      item.classList.remove("active");
    });
    item.classList.add("active");

    // Скролимо свайпер swiperCityList до відповідного слайду
    swiperCityList.slideTo(index);
  });
});

// Отримуємо перший свайпер для заголовків
const swiperScheduleTitles = new Swiper(".citys-heading-swiper", {
  slidesPerView: 7,
  spaceBetween: 12,
  breakpoints: {
    992: {
      spaceBetween: 20,
    },
  },
});

const swiperLoyality = new Swiper(".loyaliti-swiper", {
  slidesPerView: 1,
  spaceBetween: 40,
  pagination: {
    el: ".swiper-pagination",
    dynamicBullets: true,
    clickable: true,
  },
  navigation: {
    prevEl: ".loyaliti-swiper .prev",
    nextEl: ".loyaliti-swiper .next",
  },
  autoHeight: true,
  loop: true,
});

const swiperReviews = new Swiper(".reviews-patients-swiper", {
  slidesPerView: 1,
  spaceBetween: 40,
  pagination: {
    el: ".swiper-pagination",
    dynamicBullets: true,
    clickable: true,
  },
  navigation: {
    prevEl: ".reviews-patients-swiper .prev",
    nextEl: ".reviews-patients-swiper .next",
  },
  autoHeight: true,
  loop: true,
});

const swiperResultsPage = new Swiper(".results-varicose-swiper", {
  slidesPerView: 1,
  spaceBetween: 20,
  pagination: {
    el: ".swiper-pagination",
    dynamicBullets: true,
    clickable: true,
  },
  navigation: {
    prevEl: ".results-varicose-swiper .prev",
    nextEl: ".results-varicose-swiper .next",
  },
  autoHeight: true,
  loop: true,
});

// Отримуємо другий свайпер для списку міст
const swiperCityList = new Swiper(".citys-list-swiper", {
  slidesPerView: 1,
  navigation: {
    prevEl: ".citys-list-swiper .prev",
    nextEl: ".citys-list-swiper .next",
  },
  pagination: {
    el: ".swiper-pagination",
    dynamicBullets: true,
    clickable: true,
  },
  autoHeight: true,
  speed: 1500,
  loop: true,
  spaceBetween: 40,
  breakpoints: {
    992: {
      grabCursor: true,
    },
  },
});

// Додаємо обробник подій для зміни активного класу відповідно до слайда у свайпері swiperCityList
swiperCityList.on("slideChange", () => {
  const activeIndex = swiperCityList.realIndex;
  headingItems.forEach((item, index) => {
    if (index === activeIndex) {
      item.classList.add("active");
    } else {
      item.classList.remove("active");
    }
  });
});

// Перевірка на існування елементів з класом .blog-category-list__item
if (document.querySelector(".blog-category-list__item")) {
  // Додаємо клас 'active' до першого елемента
  document.querySelector(".blog-category-list__item").classList.add("active");

  document.querySelectorAll(".blog-category-list__item").forEach((item) => {
    item.addEventListener("click", function () {
      // Видаляємо клас 'active' у всіх елементів списку
      document.querySelectorAll(".blog-category-list__item").forEach((item) => {
        item.classList.remove("active");
      });
      // Додаємо клас 'active' до клікнутого елемента
      this.classList.add("active");

      const categoryName = this.getAttribute("data-category-name");
      let found = false;

      // Знаходимо перший слайд у основному слайдері, що відповідає вибраній категорії
      document
        .querySelectorAll(".main-blog-swiper .swiper-slide")
        .forEach((slide, index) => {
          const termNames = slide.getAttribute("data-term-names").split(",");
          if (!found && termNames.includes(categoryName)) {
            swiperMainBlog.slideToLoop(index); // slideToLoop використовується для циклічного переходу до слайда
            found = true;
          }
        });
    });
  });

  // Перевірка на існування елемента з класом .blog-category-list__title
  if (document.querySelector(".blog-category-list__title")) {
    // Додамо подію на клік для заголовка
    document
      .querySelector(".blog-category-list__title")
      .addEventListener("click", function () {
        // Видаляємо клас 'active' у всіх елементів списку
        document
          .querySelectorAll(".blog-category-list__item")
          .forEach((item) => {
            item.classList.remove("active");
          });
        // Додаємо клас 'active' до першого елемента списку
        document
          .querySelector(".blog-category-list__item")
          .classList.add("active");
      });
  }
}

const swiperScheduleMob = new Swiper(".citys-list-swiper--mob", {
  slidesPerView: 1,
  autoHeight: false,
  speed: 1500,
  loop: true,
  spaceBetween: 40,
  breakpoints: {
    992: {
      slidesPerView: 3,
      grabCursor: true,
    },
  },
  pagination: {
    el: ".swiper-pagination",
    dynamicBullets: true,
    clickable: true,
  },
  navigation: {
    prevEl: ".citys-list-swiper--mob .prev",
    nextEl: ".citys-list-swiper--mob .next",
  },
});

const heroSwiper = new Swiper('.main-hero-swiper', {
	spaceBetween: 20,
	slidesPerView: 1,
	loop: true,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
	},
	navigation: {
		prevEl: '.main-hero-swiper .prev',
		nextEl: '.main-hero-swiper .next',
	},
})
if(document.querySelector('.main-hero-swiper .controls .stop-button')){

	document.querySelector('.main-hero-swiper .controls .stop-button').addEventListener('click', function() {
		if (heroSwiper.autoplay.running) {
			heroSwiper.autoplay.stop();
			document.querySelector('.main-hero-swiper .controls .stop-button').classList.add('pause')
		} else {
			heroSwiper.autoplay.start();
			document.querySelector('.main-hero-swiper .controls .stop-button').classList.remove('pause')
		}
	});
}

const reviews = new Swiper('.reviews-swiper', {
	slidesPerView: 1,
	spaceBetween: 20,
	loop: true,
	pagination: {
		el: '.reviews-swiper .swiper-pagination',
		dynamicBullets: true,
		clickable: true,
	},
	navigation: {
		prevEl: '.reviews-swiper .prev',
		nextEl: '.reviews-swiper .next',
	},
})
const methods = new Swiper('.method-swiper', {
	slidesPerView: 1,
	spaceBetween: 20,
	pagination: {
		el: '.method-swiper .swiper-pagination',
		dynamicBullets: true,
		clickable: true,
	},
	navigation: {
		prevEl: '.method-swiper .prev',
		nextEl: '.method-swiper .next',
	}
})
const loyalty = new Swiper('.loyalty-swiper', {
	slidesPerView: 1,
	simulateTouch: false,
	spaceBetween: 20,
	breakpoints: {
		992:{
			slidesPerView: 4,
			simulateTouch: true,
			spaceBetween: 0
		}
	},
	navigation: {
		prevEl: '.loyalty-swiper .prev',
		nextEl: '.loyalty-swiper .next',
	},
	pagination: {
		el: '.loyalty-swiper .swiper-pagination',
		dynamicBullets: true,
		clickable: true,
	}
})


const hiddenImage = new Swiper('.withHidden_swiper', {
	slidesPerView: 1,
	spaceBetween: 20,
	simulateTouch: false,
	navigation: {
		prevEl: '.withHidden_swiper .prev',
		nextEl: '.withHidden_swiper .next',
	}
})