document.addEventListener("DOMContentLoaded", () => {
  let observer = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          carts.forEach((cart) => cart.classList.remove("is-intersectedCart"));
          entry.target.classList.add("is-intersectedCart");
        } else {
          entry.target.classList.remove("is-intersectedCart");
        }
      });
    },
    { threshold: [0, 1], rootMargin: "-50% 0px -50% 0px" }
  );
  const carts = document.querySelectorAll(".flip-card");
  carts.forEach((cart) => {
    observer.observe(cart); 
		cart.addEventListener("click", () => {
			cart.classList.toggle("is-intersectedCart");
		});
  });
});
