import refs from "./refs";

const {bodyEl} = refs;


let currentBackdrop = null;

export const showBackdrop = (backdrop, hideOnResize = false) => {
    if (!backdrop) {
        return;
    }
    disableBodyScroll();

    backdrop.removeClass("is-hidden");
    backdrop.on("click", handleBackdropClick);
    $(window).on("keydown", handleKeyDown);
    currentBackdrop = backdrop;

    if (hideOnResize) {
        $(window).on("resize", throttledHandleResize);
    }
};

export const hideBackdrop = (backdrop) => {
    if (!backdrop) {
        return;
    }

    enableBodyScroll();

    backdrop.addClass("is-hidden");
    backdrop.removeClass("click", handleBackdropClick);
    $(window).off("keydown", handleKeyDown);
    $(window).off("resize", throttledHandleResize);

    currentBackdrop = null;
};

function handleBackdropClick(e) {
    if (e.target === e.currentTarget) {
        hideBackdrop(currentBackdrop);
    }
}

function handleKeyDown(e) {
    if (e.key === "Escape") {
        hideBackdrop(currentBackdrop);
    }
}

function handleResize() {
    const {innerWidth} = window;

    if (innerWidth >= 768) {
        hideBackdrop(currentBackdrop);
    }
}

export function enableBodyScroll() {
    bodyEl.css("overflow-y", "scroll");
}

export function disableBodyScroll() {
    bodyEl.css("overflow-y", "hidden");
}


document.addEventListener('DOMContentLoaded', function() {
    const forms = document.querySelectorAll('.wpcf7-form');
    if (forms.length > 0) {
        forms.forEach(form => {
            form.addEventListener('submit', function(event) {
                event.preventDefault();

                const formData = new FormData(form);
                fetch(form.action, {
                    method: 'POST',
                    body: formData
                })
                    .then(response => {
                        if (response.ok) {
                            const tyWrapper = form.closest('.cta-wrapper__form')?.querySelector('.ty-wrapper');
                            if (tyWrapper) {
                                tyWrapper.classList.remove('is-hidden');
                            } else {
                                console.warn('Не знайдено елемент .ty-wrapper');
                            }
                        } else {
                            console.error('Помилка при відправленні форми:', response.status);
                        }
                    })
                    .catch(error => {
                        console.error('Помилка при відправленні форми:', error);
                    });
            });
        });
    } else {
        console.warn('Не знайдено елементів .wpcf7-form');
    }
});



// Перевірка на існування елементів з класом .citys-heading-list__link
if (document.querySelector('.citys-heading-list__link')) {
    const links = document.querySelectorAll('.citys-heading-list__link');

    // Додаємо клас 'active' до першого елемента за замовчуванням
    if (links.length > 0) {
        links[0].classList.add('active');
    }

    // Додаємо обробник подій для кожного елемента
    links.forEach(link => {
        link.addEventListener('click', function () {
            // Видаляємо клас 'active' у всіх елементів
            links.forEach(link => {
                link.classList.remove('active');
            });
            // Додаємо клас 'active' до клікнутого елемента
            this.classList.add('active');
        });
    });
}


$("document").ready(function () {
    bodyEl.css("visibility", "visible");
});
